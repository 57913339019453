<template>
  <el-select
    v-model="value"
    :placeholder="placeholder"
    :size="size"
    :class="className"
    :popper-class="popperClass"
    v-if="basic"
  >
    <el-option
      v-for="item in transformedChoices"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
      <slot />
    </el-option>
  </el-select>
  <div
    class="h-10 flex items-strech rounded px-2.5 bg-secondary-400"
    v-if="!basic"
  >
    <select
      class="
        w-full
        bg-transparent
        text-secondary-700
        outline-none
        font-montserrat font-semibold
      "
      :class="className"
      v-model="value"
    >
      <option value="" disabled selected>{{ placeholder }}</option>
      <option
        v-for="item in transformedChoices"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
        {{ item.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { ElSelect, ElOption } from 'element-plus'

export default defineComponent({
  name: 'Select',
  components: {
    ElSelect,
    ElOption,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Choose',
    },
    class: {
      type: String,
    },
    popperClass: {
      type: String,
    },
    modelValue: {
      type: String,
      default: '',
    },
    transform: {
      type: Function,
      default: foo => foo,
    },
    size: {
      type: String,
      validator: function (value) {
        return ['mini', 'small', 'medium', 'large'].indexOf(value) !== -1
      },
      default: 'medium',
    },
    basic: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    transformedChoices: function () {
      return this.options.map(this.transform)
    },
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val) //vue v3 @see https://v3.vuejs.org/guide/migration/v-model.html
        this.$emit('input', val) //vue v2
      },
    },
    className: function () {
      return {
        [this.class]: true,
        'text-xl': this.size === 'large',
        'text-sm': this.size === 'medium',
      }
    },
  },
})
</script>
